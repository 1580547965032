"use strict";

const { wrap_aff, wrap_maybe } = require('../../src/ForeignHelpers.js')

export const _read_local_storage = key => just => nothing => wrap_aff((left, right) => {
	const maybe = wrap_maybe(just, nothing)
	return right(maybe(window.localStorage.getItem(key)))
})

export const _write_local_storage = key => value => wrap_aff((left, right) =>
	right(window.localStorage.setItem(key, value))
)

