// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Parallel from "../Control.Parallel/index.js";
import * as Control_Parallel_Class from "../Control.Parallel.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_Config from "../Unlockable.Wallet.Config/index.js";
import * as Unlockable_Wallet_Draggable from "../Unlockable.Wallet.Draggable/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Unlockable from "../Unlockable.Wallet.Unlockable/index.js";
import * as Unlockable_Wallet_UnlockableCard from "../Unlockable.Wallet.UnlockableCard/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var elemIndex = /* #__PURE__ */ Data_Array.elemIndex(Data_Eq.eqInt);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var bind2 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var parSequence = /* #__PURE__ */ Control_Parallel.parSequence(/* #__PURE__ */ Control_Parallel_Class.monadParExceptT(Effect_Aff.parallelAff))(Data_Traversable.traversableArray);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var fromFoldable = /* #__PURE__ */ Data_Map_Internal.fromFoldable(Data_Ord.ordInt)(Data_Foldable.foldableArray);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var sortableContext = /* #__PURE__ */ Unlockable_Wallet_Draggable.sortableContext(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(Data_Ord.ordInt);
var sortableItem = /* #__PURE__ */ Unlockable_Wallet_Draggable.sortableItem(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "source";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt)));
var fromFoldableWith = /* #__PURE__ */ Data_Map_Internal.fromFoldableWith(Data_Ord.ordString)(Data_Foldable.foldableArray);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var reorder = function (order) {
    return function (dragged) {
        return function (over) {
            var reorder$prime = function (order1) {
                return function (dragged1) {
                    return function (over1) {
                        return bind(elemIndex(dragged1)(order1))(function (draggedIdx) {
                            return bind(elemIndex(over1)(order1))(function (overIdx) {
                                return bind(Data_Array.deleteAt(draggedIdx)(order1))(function (order2) {
                                    return bind(Data_Array.insertAt(overIdx)(dragged1)(order2))(function (order3) {
                                        return pure(order3);
                                    });
                                });
                            });
                        });
                    };
                };
            };
            return Data_Maybe.fromMaybe(order)(reorder$prime(order)(dragged)(over));
        };
    };
};
var reorderableCollectionWidget = /* #__PURE__ */ bind1(/* #__PURE__ */ Unlockable_Wallet_Utility.runWeb3(/* #__PURE__ */ bind2(Unlockable_Wallet_Config.getNetwork)(function (network) {
    return bind2(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
        return bind2(Unlockable_Wallet_Near.accountId(wallet))(function (account) {
            return bind2(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                return bind2(Unlockable_Wallet_Unlockable.get_holdings(contr)(account))(function (held) {
                    return parSequence(mapFlipped(held)(function (v) {
                        return bind2(Unlockable_Wallet_UnlockableCard.getUnlockableHolding(v.designer_account_id)(v.unlockable_name))(function (details) {
                            return pure1({
                                designer_account_id: v.designer_account_id,
                                unlockable_name: v.unlockable_name,
                                details: details
                            });
                        });
                    }));
                });
            });
        });
    });
})))(function (unlockablesHeld) {
    var startingOrder = Data_Array.range(0)(Data_Array.length(unlockablesHeld) - 1 | 0);
    var indexMap = fromFoldable(Data_Array.zip(startingOrder)(unlockablesHeld));
    var go = function (order) {
        return function (held) {
            return bind1(div([ Unlockable_Wallet_Bootstrap.clazz([ "container", "context" ]) ])([ sortableContext([ Unlockable_Wallet_Draggable.items(order), Unlockable_Wallet_Draggable.onDropHandler ])(mapFlipped(order)(function (i) {
                var v = lookup(i)(held);
                if (v instanceof Data_Maybe.Just && v.value0.details instanceof Data_Maybe.Just) {
                    return sortableItem([ Unlockable_Wallet_Draggable.itemId(i) ])([ Unlockable_Wallet_UnlockableCard["unlockableCard$prime"](v.value0.designer_account_id)(v.value0.unlockable_name)(v.value0.details.value0) ]);
                };
                return text("bug!");
            })) ]))(function (reorderEvent) {
                return bind1(liftEffect(Effect_Console.log(show(reorderEvent))))(function () {
                    return go(reorder(order)(reorderEvent.source)(reorderEvent.target))(held);
                });
            });
        };
    };
    return go(startingOrder)(indexMap);
});
var holdingsWidget = /* #__PURE__ */ bind1(/* #__PURE__ */ Unlockable_Wallet_Utility.runWeb3(/* #__PURE__ */ bind2(Unlockable_Wallet_Config.getNetwork)(function (network) {
    return bind2(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
        return bind2(Unlockable_Wallet_Near.accountId(wallet))(function (account) {
            return bind2(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                return Unlockable_Wallet_Unlockable.get_holdings(contr)(account);
            });
        });
    });
})))(function (details) {
    return div([ Unlockable_Wallet_Bootstrap.claz("container") ])([ div([ Unlockable_Wallet_Bootstrap.claz("row") ])(mapFlipped(details)(function (v) {
        return Unlockable_Wallet_UnlockableCard.unlockableCard(v.designer_account_id)(v.unlockable_name);
    })) ]);
});
var holdings = /* #__PURE__ */ Unlockable_Wallet_Bootstrap.connectWalletThen(reorderableCollectionWidget);
var group_by_designer = function (input) {
    return fromFoldableWith(append)(mapFlipped(input)(function (v) {
        return new Data_Tuple.Tuple(v.designer_account_id, [ v ]);
    }));
};
export {
    reorder,
    reorderableCollectionWidget,
    holdingsWidget,
    group_by_designer,
    holdings
};
