// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Effect_Uncurried from "../Effect.Uncurried/index.js";
import * as React from "../React/index.js";
import * as React_DOM_Props from "../React.DOM.Props/index.js";
var sortableItem = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return Concur_React_DOM["el$prime"](dictShiftMap)(dictMultiAlternative)((function () {
            var $9 = React.unsafeCreateElement($foreign["_wrappedSortableItem"]);
            return function ($10) {
                return $9(React_DOM_Props.unsafeFromPropsArray($10));
            };
        })());
    };
};
var sortableContext = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return Concur_React_DOM["el$prime"](dictShiftMap)(dictMultiAlternative)((function () {
            var $11 = React.unsafeCreateElement($foreign["_wrappedSortableContext"]);
            return function ($12) {
                return $11(React_DOM_Props.unsafeFromPropsArray($12));
            };
        })());
    };
};
var onDrop = function (f) {
    return React_DOM_Props.unsafeMkProps("onDrop")(Effect_Uncurried.mkEffectFn1(f));
};
var onDropHandler = /* #__PURE__ */ (function () {
    return new Concur_Core_Props.Handler(onDrop);
})();
var items = /* #__PURE__ */ (function () {
    var $13 = React_DOM_Props.unsafeMkProps("items");
    return function ($14) {
        return Concur_Core_Props.PrimProp.create($13($14));
    };
})();
var itemId = /* #__PURE__ */ (function () {
    var $15 = React_DOM_Props.unsafeMkProps("id");
    return function ($16) {
        return Concur_Core_Props.PrimProp.create($15($16));
    };
})();
export {
    _wrappedSortableContext,
    _wrappedSortableItem
} from "./foreign.js";
export {
    sortableContext,
    sortableItem,
    items,
    itemId,
    onDrop,
    onDropHandler
};
