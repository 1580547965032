// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_Config from "../Unlockable.Wallet.Config/index.js";
import * as Unlockable_Wallet_FileUpload from "../Unlockable.Wallet.FileUpload/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Unlockable from "../Unlockable.Wallet.Unlockable/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.widgetBind);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.widgetApplicative);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h5 = /* #__PURE__ */ Concur_React_DOM.h5(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var input = /* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Props.functorProps);
var label = /* #__PURE__ */ Concur_React_DOM.label(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Concur_Core_Props.functorProps);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var bind2 = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var img = /* #__PURE__ */ Concur_React_DOM.img(Concur_Core_LiftWidget.widgetLiftWidget);
var li = /* #__PURE__ */ Concur_React_DOM.li(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var mapFlipped1 = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var IconEditForm_IconInputChanged = /* #__PURE__ */ (function () {
    function IconEditForm_IconInputChanged(value0) {
        this.value0 = value0;
    };
    IconEditForm_IconInputChanged.create = function (value0) {
        return new IconEditForm_IconInputChanged(value0);
    };
    return IconEditForm_IconInputChanged;
})();
var IconEditForm_FileChanged = /* #__PURE__ */ (function () {
    function IconEditForm_FileChanged(value0) {
        this.value0 = value0;
    };
    IconEditForm_FileChanged.create = function (value0) {
        return new IconEditForm_FileChanged(value0);
    };
    return IconEditForm_FileChanged;
})();
var IconEditForm_SubmitButtonClicked = /* #__PURE__ */ (function () {
    function IconEditForm_SubmitButtonClicked() {

    };
    IconEditForm_SubmitButtonClicked.value = new IconEditForm_SubmitButtonClicked();
    return IconEditForm_SubmitButtonClicked;
})();
var IconEditForm_CancelButtonClicked = /* #__PURE__ */ (function () {
    function IconEditForm_CancelButtonClicked() {

    };
    IconEditForm_CancelButtonClicked.value = new IconEditForm_CancelButtonClicked();
    return IconEditForm_CancelButtonClicked;
})();
var EnrollmentForm_NameInputChanged = /* #__PURE__ */ (function () {
    function EnrollmentForm_NameInputChanged(value0) {
        this.value0 = value0;
    };
    EnrollmentForm_NameInputChanged.create = function (value0) {
        return new EnrollmentForm_NameInputChanged(value0);
    };
    return EnrollmentForm_NameInputChanged;
})();
var EnrollmentForm_IconInputChanged = /* #__PURE__ */ (function () {
    function EnrollmentForm_IconInputChanged(value0) {
        this.value0 = value0;
    };
    EnrollmentForm_IconInputChanged.create = function (value0) {
        return new EnrollmentForm_IconInputChanged(value0);
    };
    return EnrollmentForm_IconInputChanged;
})();
var EnrollmentForm_SubmitButtonClicked = /* #__PURE__ */ (function () {
    function EnrollmentForm_SubmitButtonClicked() {

    };
    EnrollmentForm_SubmitButtonClicked.value = new EnrollmentForm_SubmitButtonClicked();
    return EnrollmentForm_SubmitButtonClicked;
})();
var DesignForm_NameInputChanged = /* #__PURE__ */ (function () {
    function DesignForm_NameInputChanged(value0) {
        this.value0 = value0;
    };
    DesignForm_NameInputChanged.create = function (value0) {
        return new DesignForm_NameInputChanged(value0);
    };
    return DesignForm_NameInputChanged;
})();
var DesignForm_SupplyInputChanged = /* #__PURE__ */ (function () {
    function DesignForm_SupplyInputChanged(value0) {
        this.value0 = value0;
    };
    DesignForm_SupplyInputChanged.create = function (value0) {
        return new DesignForm_SupplyInputChanged(value0);
    };
    return DesignForm_SupplyInputChanged;
})();
var DesignForm_SubmitButtonClicked = /* #__PURE__ */ (function () {
    function DesignForm_SubmitButtonClicked() {

    };
    DesignForm_SubmitButtonClicked.value = new DesignForm_SubmitButtonClicked();
    return DesignForm_SubmitButtonClicked;
})();
var unlockableIconEditWidget = function (unlockable_name) {
    return bind(a([ Concur_React_Props.className("card-link"), Concur_React_Props.onClick ])([ text("Edit icon") ]))(function () {
        return bind(Unlockable_Wallet_FileUpload.fileUploadWidget)(function (contents) {
            return discard(alt(span([ Concur_React_Props.className("card-link") ])([ text("...") ]))(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
                return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
                    return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                        return Unlockable_Wallet_Unlockable.set_unlockable_icon(contr)(unlockable_name)(Unlockable_Wallet_FileUpload.toBlob(contents));
                    });
                });
            }))))(function () {
                return pure(Data_Unit.unit);
            });
        });
    });
};
var enrollmentForm = function (state) {
    return bind(div([  ])([ h5([  ])([ text("Setup your designer profile:") ]), div([ Unlockable_Wallet_Bootstrap.clazz([ "col-12", "form-floating", "mb-3" ]) ])([ input([ Concur_React_Props["_type"]("text"), Unlockable_Wallet_Bootstrap.claz("form-control"), Concur_React_Props["_id"]("name"), map(function ($68) {
        return EnrollmentForm_NameInputChanged.create(Concur_React_Props.unsafeTargetValue($68));
    })(Concur_React_Props.onChange) ]), label([ Unlockable_Wallet_Bootstrap["_for"]("name") ])([ text("Your designer name") ]) ]), div([ Unlockable_Wallet_Bootstrap.clazz([ "col-12", "form-floating", "mb-3" ]) ])([ input([ Concur_React_Props["_type"]("text"), Unlockable_Wallet_Bootstrap.claz("form-control"), Concur_React_Props["_id"]("icon") ]), label([ Unlockable_Wallet_Bootstrap["_for"]("icon") ])([ text("Designer icon goes here... TBD") ]) ]), button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-primary" ]), voidRight(EnrollmentForm_SubmitButtonClicked.value)(Concur_React_Props.onClick) ])([ text("Enroll") ]) ]))(function (action) {
        if (action instanceof EnrollmentForm_NameInputChanged) {
            return enrollmentForm({
                name: action.value0,
                icon: state.icon
            });
        };
        if (action instanceof EnrollmentForm_IconInputChanged) {
            return enrollmentForm({
                name: state.name,
                icon: action.value0
            });
        };
        if (action instanceof EnrollmentForm_SubmitButtonClicked) {
            return pure(state);
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Designer (line 75, column 5 - line 78, column 57): " + [ action.constructor.name ]);
    });
};
var designForm = function (state) {
    return bind(div([  ])([ h5([  ])([ text("Create a new unlockable:") ]), div([ Unlockable_Wallet_Bootstrap.clazz([ "col-12", "form-floating", "mb-3" ]) ])([ input([ Concur_React_Props["_type"]("text"), Unlockable_Wallet_Bootstrap.claz("form-control"), Concur_React_Props["_id"]("name"), mapFlipped(Concur_React_Props.onChange)(function ($69) {
        return DesignForm_NameInputChanged.create(Concur_React_Props.unsafeTargetValue($69));
    }) ]), label([ Unlockable_Wallet_Bootstrap["_for"]("name") ])([ text("Unlockable name") ]) ]), div([ Unlockable_Wallet_Bootstrap.clazz([ "col-12", "form-floating", "mb-3" ]) ])([ input([ Concur_React_Props["_type"]("text"), Unlockable_Wallet_Bootstrap.claz("form-control"), Concur_React_Props["_id"]("supply"), mapFlipped(Concur_React_Props.onChange)(function ($70) {
        return DesignForm_SupplyInputChanged.create((function (i) {
            return Data_Maybe.fromMaybe(state.supply)(Data_Int.fromString(i));
        })(Concur_React_Props.unsafeTargetValue($70)));
    }), Concur_React_Props.value(show(state.supply)) ]), label([ Unlockable_Wallet_Bootstrap["_for"]("icon") ])([ text("Maximum supply") ]) ]), button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-primary" ]), voidRight(DesignForm_SubmitButtonClicked.value)(Concur_React_Props.onClick) ])([ text("Create!") ]) ]))(function (action) {
        if (action instanceof DesignForm_NameInputChanged) {
            return designForm({
                name: action.value0,
                supply: state.supply
            });
        };
        if (action instanceof DesignForm_SupplyInputChanged) {
            return designForm({
                name: state.name,
                supply: action.value0
            });
        };
        if (action instanceof DesignForm_SubmitButtonClicked) {
            return pure(state);
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Designer (line 106, column 5 - line 109, column 53): " + [ action.constructor.name ]);
    });
};
var defaultEnrollmentFormState = {
    name: "",
    icon: ""
};
var enrollmentWidgetThen = function (widget) {
    return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
        return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
            return bind1(Unlockable_Wallet_Near.accountId(wallet))(function (account) {
                return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                    return bind1(Unlockable_Wallet_Unlockable.get_designer(contr)(account))(function (enrollment) {
                        return pure1(enrollment);
                    });
                });
            });
        });
    })))(function (value) {
        if (value instanceof Data_Maybe.Nothing) {
            return bind(div([ Concur_React_Props.className("container") ])([ div([ Concur_React_Props.className("row") ])([ enrollmentForm(defaultEnrollmentFormState) ]) ]))(function (newEnrollment) {
                return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
                    return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
                        return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                            return Unlockable_Wallet_Unlockable.enroll(contr)(newEnrollment.name)(newEnrollment.icon);
                        });
                    });
                })))(function () {
                    return enrollmentWidgetThen(widget);
                });
            });
        };
        if (value instanceof Data_Maybe.Just) {
            return alt(div([ Concur_React_Props.className("container") ])([ div([ Concur_React_Props.className("row") ])([ h5([  ])([ text(value.value0.name + "'s designs:") ]) ]) ]))(widget);
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Designer (line 121, column 5 - line 149, column 27): " + [ value.constructor.name ]);
    });
};
var defaultDesignFormState = {
    name: "",
    supply: 100
};
var collectLinkWidget = function (designer_account_id) {
    return function (unlockable_name) {
        return bind(liftEffect(function __do() {
            var protocol$prime = bind2(bind2(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.protocol)();
            var host$prime = bind2(bind2(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.host)();
            return protocol$prime + ("//" + host$prime);
        }))(function (base) {
            var link = $foreign["_encodeURI"](base + ("/collect/" + (designer_account_id + ("/" + unlockable_name))));
            return a([ Concur_React_Props.className("card-link"), Concur_React_Props.href(link) ])([ text("Collector link") ]);
        });
    };
};
var designWidget = function (designer_account_id) {
    return function (unlockable_name) {
        return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
            return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
                return bind1(Unlockable_Wallet_Near.accountId(wallet))(function (myAccountId) {
                    return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contract) {
                        return bind1(Unlockable_Wallet_Unlockable.get_designer(contract)(designer_account_id))(function (designer_details_maybe) {
                            return bind1(Unlockable_Wallet_Unlockable.get_unlockable(contract)(designer_account_id)(unlockable_name))(function (unlockable_details_maybe) {
                                return bind1(Unlockable_Wallet_Unlockable.get_holders(contract)(designer_account_id)(unlockable_name))(function (holders) {
                                    return pure1({
                                        designer_details_maybe: designer_details_maybe,
                                        unlockable_details_maybe: unlockable_details_maybe,
                                        holders: holders
                                    });
                                });
                            });
                        });
                    });
                });
            });
        })))(function (v) {
            var $54 = {
                designer_details_maybe: v.designer_details_maybe,
                unlockable_details_maybe: v.unlockable_details_maybe,
                holders: v.holders
            };
            if ($54.designer_details_maybe instanceof Data_Maybe.Just && $54.unlockable_details_maybe instanceof Data_Maybe.Just) {
                var icon = (function () {
                    if ($54.unlockable_details_maybe.value0.icon instanceof Data_Maybe.Nothing) {
                        return img([ Concur_React_Props.className("card-img-top"), Concur_React_Props.src("/images/missing.gif") ]);
                    };
                    if ($54.unlockable_details_maybe.value0.icon instanceof Data_Maybe.Just) {
                        return img([ Concur_React_Props.className("card-img-top"), Concur_React_Props.src($54.unlockable_details_maybe.value0.icon.value0) ]);
                    };
                    throw new Error("Failed pattern match at Unlockable.Wallet.Designer (line 175, column 24 - line 177, column 92): " + [ $54.unlockable_details_maybe.value0.icon.constructor.name ]);
                })();
                return bind(div([ Concur_React_Props.className("card"), Concur_React_Props.style({
                    width: "240px"
                }) ])([ icon, div([ Concur_React_Props.className("card-header") ])([ h5([ Concur_React_Props.className("card-title") ])([ text(unlockable_name) ]) ]), div([ Concur_React_Props.className("list-group list-group-flush"), Concur_React_Props.title("Held by " + Data_String_Common.joinWith(", ")($54.holders)) ])([ li([ Concur_React_Props.className("list-group-item") ])([ text(show($54.unlockable_details_maybe.value0.available)), text(" of "), text(show($54.unlockable_details_maybe.value0.supply)), text(" available") ]) ]), div([ Concur_React_Props.className("card-body") ])([ collectLinkWidget(designer_account_id)(unlockable_name), unlockableIconEditWidget(unlockable_name) ]) ]))(function () {
                    return designWidget(designer_account_id)(unlockable_name);
                });
            };
            return text("goodbye");
        });
    };
};
var $lazy_designsWidget = /* #__PURE__ */ $runtime_lazy("designsWidget", "Unlockable.Wallet.Designer", function () {
    return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
        return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
            return bind1(Unlockable_Wallet_Near.accountId(wallet))(function (account) {
                return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                    return bind1(Unlockable_Wallet_Unlockable.get_designs(contr)(account))(function (designs) {
                        return pure1({
                            account: account,
                            designs: designs
                        });
                    });
                });
            });
        });
    })))(function (v) {
        return bind(div([ Unlockable_Wallet_Bootstrap.claz("container") ])([ div([ Unlockable_Wallet_Bootstrap.claz("row") ])(mapFlipped1(v.designs)(function (unlockable_name) {
            return designWidget(v.account)(unlockable_name);
        })), div([ Unlockable_Wallet_Bootstrap.claz("row") ])([  ]), designForm(defaultDesignFormState) ]))(function (newDesign) {
            return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
                return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
                    return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                        return Unlockable_Wallet_Unlockable.design(contr)(newDesign.name)(newDesign.supply);
                    });
                });
            })))(function () {
                return $lazy_designsWidget(233);
            });
        });
    });
});
var designsWidget = /* #__PURE__ */ $lazy_designsWidget(205);
var designer = /* #__PURE__ */ Unlockable_Wallet_Bootstrap.connectWalletThen(/* #__PURE__ */ enrollmentWidgetThen(designsWidget));
export {
    _encodeURI
} from "./foreign.js";
export {
    EnrollmentForm_NameInputChanged,
    EnrollmentForm_IconInputChanged,
    EnrollmentForm_SubmitButtonClicked,
    defaultEnrollmentFormState,
    DesignForm_NameInputChanged,
    DesignForm_SupplyInputChanged,
    DesignForm_SubmitButtonClicked,
    defaultDesignFormState,
    enrollmentForm,
    designForm,
    enrollmentWidgetThen,
    designWidget,
    designsWidget,
    IconEditForm_IconInputChanged,
    IconEditForm_FileChanged,
    IconEditForm_SubmitButtonClicked,
    IconEditForm_CancelButtonClicked,
    unlockableIconEditWidget,
    collectLinkWidget,
    designer
};
