// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Parallel from "../Control.Parallel/index.js";
import * as Control_Parallel_Class from "../Control.Parallel.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_Config from "../Unlockable.Wallet.Config/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Unlockable from "../Unlockable.Wallet.Unlockable/index.js";
import * as Unlockable_Wallet_UnlockableCard from "../Unlockable.Wallet.UnlockableCard/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h4$prime = /* #__PURE__ */ Concur_React_DOM["h4$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var p$prime = /* #__PURE__ */ Concur_React_DOM["p$prime"](widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var br$prime = /* #__PURE__ */ Concur_React_DOM["br$prime"](Concur_Core_LiftWidget.widgetLiftWidget);
var show = /* #__PURE__ */ Data_Show.show(Unlockable_Wallet_Utility.showWeb3Error);
var img = /* #__PURE__ */ Concur_React_DOM.img(Concur_Core_LiftWidget.widgetLiftWidget);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidLeft = /* #__PURE__ */ Data_Functor.voidLeft(Concur_Core_Props.functorProps);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var map = /* #__PURE__ */ Data_Functor.map(Concur_Core_Types.widgetFunctor);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var voidLeft1 = /* #__PURE__ */ Data_Functor.voidLeft(Concur_Core_Types.widgetFunctor);
var parSequence = /* #__PURE__ */ Control_Parallel.parSequence(/* #__PURE__ */ Control_Parallel_Class.monadParExceptT(Effect_Aff.parallelAff))(Data_Traversable.traversableArray);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Effect_Aff_Class.monadAffExceptT(Effect_Aff_Class.monadAffAff));
var voidLeft2 = /* #__PURE__ */ Data_Functor.voidLeft(Effect_Aff.functorAff);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.widgetBind);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.widgetApplicative);
var ChestClosed = /* #__PURE__ */ (function () {
    function ChestClosed() {

    };
    ChestClosed.value = new ChestClosed();
    return ChestClosed;
})();
var ChestOpening = /* #__PURE__ */ (function () {
    function ChestOpening() {

    };
    ChestOpening.value = new ChestOpening();
    return ChestOpening;
})();
var ChestOpened = /* #__PURE__ */ (function () {
    function ChestOpened() {

    };
    ChestOpened.value = new ChestOpened();
    return ChestOpened;
})();
var ChestError = /* #__PURE__ */ (function () {
    function ChestError() {

    };
    ChestError.value = new ChestError();
    return ChestError;
})();
var errorDisplay = function (_error) {
    return div([ Unlockable_Wallet_Bootstrap.clazz([ "overlay", "center-content", "text-center" ]) ])([ div([ Unlockable_Wallet_Bootstrap.clazz([ "alert", "alert-danger" ]) ])([ h4$prime([ text("On No!") ]), p$prime([ text("Something terrible happened..."), br$prime, text(show(_error)), br$prime, text("Maybe try again?") ]) ]) ]);
};
var chestImageSrc = function (state) {
    if (state instanceof ChestClosed) {
        return "/images/unlockable_chest/default/closed.png";
    };
    if (state instanceof ChestOpening) {
        return "/images/unlockable_chest/default/closed.png";
    };
    if (state instanceof ChestOpened) {
        return "/images/unlockable_chest/default/opened.png";
    };
    if (state instanceof ChestError) {
        return "/images/unlockable_chest/default/error.png";
    };
    throw new Error("Failed pattern match at Unlockable.Wallet.UnlockableClaim (line 105, column 23 - line 109, column 65): " + [ state.constructor.name ]);
};
var chestClassName = function (state) {
    if (state instanceof ChestClosed) {
        return "closed";
    };
    if (state instanceof ChestOpening) {
        return "opening";
    };
    if (state instanceof ChestOpened) {
        return "opened";
    };
    if (state instanceof ChestError) {
        return "";
    };
    throw new Error("Failed pattern match at Unlockable.Wallet.UnlockableClaim (line 98, column 24 - line 102, column 23): " + [ state.constructor.name ]);
};
var chestWidget = function (state) {
    return div([ Unlockable_Wallet_Bootstrap.clazz([ "overlay", "center-content", "unlockable-chest-container", chestClassName(state) ]) ])([ div([ Unlockable_Wallet_Bootstrap.claz("grow") ])([  ]), div([ Unlockable_Wallet_Bootstrap.claz("my-3") ])([ img([ Concur_React_Props.src(chestImageSrc(state)), Unlockable_Wallet_Bootstrap.clazz([ "unlockable-chest-image", chestClassName(state) ]) ]) ]), div([ Unlockable_Wallet_Bootstrap.claz("grow") ])((function () {
        if (state instanceof ChestClosed) {
            return [ button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-primary" ]), voidLeft(Concur_React_Props.onClick)(Data_Unit.unit) ])([ text("Unlock!"), img([ Unlockable_Wallet_Bootstrap.claz("unlockable-chest-key"), Concur_React_Props.src("/images/key_1.png") ]) ]) ];
        };
        if (state instanceof ChestOpening) {
            return [ button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-primary" ]), Concur_React_Props.disabled(true) ])([ text("Unlocking...") ]) ];
        };
        if (state instanceof ChestOpened) {
            return [ button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-primary" ]), Concur_React_Props.disabled(true) ])([ text("Opened!") ]) ];
        };
        if (state instanceof ChestError) {
            return [ button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-primary" ]), Concur_React_Props.disabled(true) ])([ text("Error!") ]) ];
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.UnlockableClaim (line 68, column 14 - line 88, column 22): " + [ state.constructor.name ]);
    })()) ]);
};
var cardWidget = function (designerAccountId) {
    return function (unlockableName) {
        return function (unlockableCardDetails) {
            return function (shown) {
                return div([ Unlockable_Wallet_Bootstrap.clazz([ "overlay", "center-content", "unlockable-chest-treasure", (function () {
                    if (shown) {
                        return "";
                    };
                    return "hidden";
                })() ]) ])([ Unlockable_Wallet_UnlockableCard["unlockableCard$prime"](designerAccountId)(unlockableName)(unlockableCardDetails) ]);
            };
        };
    };
};
var unlockableClaim = function (designerAccountId) {
    return function (unlockableName) {
        return bind(chestWidget(ChestClosed.value))(function () {
            return bind(map(function ($37) {
                return Data_Array.head(Data_Array.catMaybes($37));
            })(alt(voidLeft1(chestWidget(ChestOpening.value))([  ]))(Unlockable_Wallet_Utility.runWeb3(parSequence([ bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
                return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
                    return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contract) {
                        return bind1(Unlockable_Wallet_Unlockable.collect(contract)(designerAccountId)(unlockableName))(function () {
                            return bind1(Unlockable_Wallet_UnlockableCard.getUnlockableHolding(designerAccountId)(unlockableName))(function (unlockableHolding) {
                                return pure(unlockableHolding);
                            });
                        });
                    });
                });
            }), liftAff(voidLeft2(Effect_Aff.delay(2000.0))(Data_Maybe.Nothing.value)) ])))))(function (collectable) {
                return discard(Data_Maybe.maybe(alt(chestWidget(ChestError.value))(errorDisplay(new Unlockable_Wallet_Utility.OtherError("hmmmm..."))))(function (res) {
                    return alt(chestWidget(ChestOpened.value))(cardWidget(designerAccountId)(unlockableName)(res)(true));
                })(collectable))(function () {
                    return pure1(Data_Unit.unit);
                });
            });
        });
    };
};
export {
    ChestClosed,
    ChestOpening,
    ChestOpened,
    ChestError,
    unlockableClaim,
    chestWidget,
    cardWidget,
    chestClassName,
    chestImageSrc,
    errorDisplay
};
