"use strict";


const nearAPI = require("near-api-js");
const borsh = require('borsh');
const { connect, keyStores, WalletConnection, KeyPair } = nearAPI;

const js_sha512 = require("js-sha512");
const noble = require('@noble/ed25519');

const accountName = "2.burner.unlockable.testnet"

const configs = {
	mainnet: {
		networkId: "mainnet",
		keyStore: new keyStores.BrowserLocalStorageKeyStore(),
		nodeUrl: "https://rpc.mainnet.near.org",
		walletUrl: "https://wallet.mainnet.near.org",
		helperUrl: "https://helper.mainnet.near.org",
		explorerUrl: "https://explorer.mainnet.near.org",
	},
	testnet: {
		networkId: "testnet",
		keyStore: new keyStores.BrowserLocalStorageKeyStore(),
		nodeUrl: "https://rpc.testnet.near.org",
		walletUrl: "https://wallet.testnet.near.org",
		helperUrl: "https://helper.testnet.near.org",
		explorerUrl: "https://explorer.testnet.near.org",
	},
}

// This is utter rot - and i have no idea how to do this in a foreign module
//  - probably the solution is to use a proper build chain / bundler before
//    spago builds (TODO?)
//
const { wrap_aff, wrap_maybe, buffer_to_hex_string } = require('../../src/ForeignHelpers.js')

export const _connectWallet = network => wrap_aff((left, right) =>
	// Must check network exists as key in config probably?
	//
	connect(configs[network])
		.then((near) => new WalletConnection(near))
		.then(right)
)

export const _loadContract = wallet => accountId => viewMethods => changeMethods => wrap_aff((left, right) =>
	right(new nearAPI.Contract(wallet.account(), accountId, { viewMethods, changeMethods }))
)

export const _walletConnected = wallet => wrap_aff((left, right) => {
	console.log("_walletConnected")
	return right(wallet.isSignedIn())
})

export const _signInWallet = wallet => wrap_aff((left, right) =>
	wallet
		.requestSignIn({
			contractId: accountName,
			// TODO - needs method list specified (used for RPC direct method)
			methodNames: ["set_unlockable_icon", "collect"],
		})
		.then(right)
)

export const _signOutWallet = wallet => wrap_aff((left, right) =>
	right(wallet.signOut())
)

export const _accountId = wallet => wrap_aff((left, right) =>
	right(wallet.getAccountId())
)

