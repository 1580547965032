// Generated by purs version 0.15.4
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Eq_Generic from "../Data.Eq.Generic/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Effect from "../Effect/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Generic from "../Routing.Duplex.Generic/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var HoldingsIsSymbol = {
    reflectSymbol: function () {
        return "Holdings";
    }
};
var CollectIsSymbol = {
    reflectSymbol: function () {
        return "Collect";
    }
};
var DesignerIsSymbol = {
    reflectSymbol: function () {
        return "Designer";
    }
};
var APIIsSymbol = {
    reflectSymbol: function () {
        return "API";
    }
};
var PlayIsSymbol = {
    reflectSymbol: function () {
        return "Play";
    }
};
var NotFoundIsSymbol = {
    reflectSymbol: function () {
        return "NotFound";
    }
};
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var genericShowArgsArgument = /* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showString);
var genericEqConstructor = /* #__PURE__ */ Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments);
var genericEqSum = /* #__PURE__ */ Data_Eq_Generic.genericEqSum(genericEqConstructor);
var genericEqArgument = /* #__PURE__ */ Data_Eq_Generic.genericEqArgument(Data_Eq.eqString);
var bind = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var Holdings = /* #__PURE__ */ (function () {
    function Holdings() {

    };
    Holdings.value = new Holdings();
    return Holdings;
})();
var Collect = /* #__PURE__ */ (function () {
    function Collect(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Collect.create = function (value0) {
        return function (value1) {
            return new Collect(value0, value1);
        };
    };
    return Collect;
})();
var Designer = /* #__PURE__ */ (function () {
    function Designer() {

    };
    Designer.value = new Designer();
    return Designer;
})();
var API = /* #__PURE__ */ (function () {
    function API() {

    };
    API.value = new API();
    return API;
})();
var Play = /* #__PURE__ */ (function () {
    function Play() {

    };
    Play.value = new Play();
    return Play;
})();
var NotFound = /* #__PURE__ */ (function () {
    function NotFound() {

    };
    NotFound.value = new NotFound();
    return NotFound;
})();
var genericRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Holdings.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Collect(x.value0.value0.value0, x.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return Designer.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return API.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return Play.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return NotFound.value;
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Routing (line 23, column 1 - line 23, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Holdings) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Collect) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1)));
        };
        if (x instanceof Designer) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof API) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof Play) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof NotFound) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Routing (line 23, column 1 - line 23, column 48): " + [ x.constructor.name ]);
    }
};
var routes = /* #__PURE__ */ Routing_Duplex.root(/* #__PURE__ */ Routing_Duplex_Generic.sum(genericRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(HoldingsIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(CollectIsSymbol)()(Routing_Duplex_Generic.gRouteProduct))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(DesignerIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(APIIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(PlayIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(NotFoundIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments)))))))({
    Holdings: Routing_Duplex_Generic.noArgs,
    Collect: /* #__PURE__ */ Routing_Duplex.path("collect")(/* #__PURE__ */ Routing_Duplex_Generic.product(Routing_Duplex_Generic.gRouteAll)(/* #__PURE__ */ Routing_Duplex.string(Routing_Duplex.segment))(/* #__PURE__ */ Routing_Duplex.string(Routing_Duplex.segment))),
    Designer: /* #__PURE__ */ Routing_Duplex.path("designer")(Routing_Duplex_Generic.noArgs),
    API: /* #__PURE__ */ Routing_Duplex.path("api")(Routing_Duplex_Generic.noArgs),
    Play: /* #__PURE__ */ Routing_Duplex.path("play")(Routing_Duplex_Generic.noArgs),
    NotFound: /* #__PURE__ */ Routing_Duplex.path("notfound")(Routing_Duplex_Generic.noArgs)
}));
var parseRoute = function (pathname) {
    return Data_Either.either(function (v) {
        return NotFound.value;
    })(identity)(Routing_Duplex.parse(routes)(pathname));
};
var printRoute = /* #__PURE__ */ Routing_Duplex.print(routes);
var showRoute = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericRoute)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(HoldingsIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(genericShowArgsArgument)(genericShowArgsArgument))(CollectIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(DesignerIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(APIIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(PlayIsSymbol))(/* #__PURE__ */ genericShowConstructor(NotFoundIsSymbol)))))))
};
var eqRoute = {
    eq: /* #__PURE__ */ Data_Eq_Generic.genericEq(genericRoute)(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ Data_Eq_Generic.genericEqSum(/* #__PURE__ */ Data_Eq_Generic.genericEqConstructor(/* #__PURE__ */ Data_Eq_Generic.genericEqProduct(genericEqArgument)(genericEqArgument)))(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(genericEqConstructor))))))
};
var currentRoute = /* #__PURE__ */ Data_Functor.mapFlipped(Effect.functorEffect)(/* #__PURE__ */ bind(/* #__PURE__ */ bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.pathname))(parseRoute);
export {
    Holdings,
    Collect,
    Designer,
    API,
    Play,
    NotFound,
    routes,
    parseRoute,
    printRoute,
    currentRoute,
    genericRoute,
    showRoute,
    eqRoute
};
