"use strict";

exports.wrap_maybe = (just, nothing) => value => value != null ? just(value) : nothing;

// Lekker standard way to handle the (Either Error a) result type of foreign calls
//
exports.wrap_aff = aff_func => left => right => (_, resolve) => {
	try {
		Promise.resolve(aff_func(left, right))
			.then((result) => resolve(result))
			.catch((err) => {
				// Gotta handle a bunch of different error types here - so safest is to just
				// convert to string (if not already)
				if (typeof err === 'string' || err instanceof String) {
					return resolve(left(err))
				} else {
					return resolve(left(err.toString()))
				}
			})
	}
	catch (e) {
		// Exception thrown here means synchronous issue, but we must ensure we
		// act asynchronously, so defer the exception via setTimeout
		//
		console.log(e.message)
		setTimeout(() => resolve(left(e.message)), 1)
	}
	return (cancelError, onCancelerError, onCancelerSuccess) => onCancelerSuccess();
}

exports.buffer_to_hex_string = (buffer) => {
	return [...new Uint8Array(buffer)]
		.map(x => x.toString(16).padStart(2, '0'))
		.join('');
}