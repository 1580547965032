// Generated by purs version 0.15.4
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_Config from "../Unlockable.Wallet.Config/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Unlockable from "../Unlockable.Wallet.Unlockable/index.js";
import * as Unlockable_Wallet_UnlockableCard from "../Unlockable.Wallet.UnlockableCard/index.js";
import * as Unlockable_Wallet_UnlockableClaim from "../Unlockable.Wallet.UnlockableClaim/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var collectWidget = function (designer_account_id) {
    return function (unlockable_name) {
        return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
            return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
                return bind1(Unlockable_Wallet_Near.accountId(wallet))(function (account) {
                    return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contr) {
                        return Unlockable_Wallet_Unlockable.is_holder(contr)(designer_account_id)(unlockable_name)(account);
                    });
                });
            });
        })))(function (alreadyHolding) {
            if (alreadyHolding) {
                return div([ Unlockable_Wallet_Bootstrap.clazz([ "overlay", "center-content" ]) ])([ Unlockable_Wallet_UnlockableCard.unlockableCard(designer_account_id)(unlockable_name) ]);
            };
            return Unlockable_Wallet_UnlockableClaim.unlockableClaim(designer_account_id)(unlockable_name);
        });
    };
};
var collect = function (designer_account_id) {
    return function (unlockable_name) {
        return Unlockable_Wallet_Bootstrap.connectWalletThen(collectWidget(designer_account_id)(unlockable_name));
    };
};
export {
    collectWidget,
    collect
};
