"use strict";

export const _get_file_contents = event => left => right => (_, resolve) => {
	try {
		let singleFile = event.target.files[0]
		let reader = new FileReader()

		reader.onload = function() {
			let mimeType = event.target.files[0].type
			
			// btoa() is OK in browser i think? 
			//
			let base64Contents = btoa(String.fromCharCode.apply(null, new Uint8Array(reader.result)));

			console.log(reader.result)
			resolve(right({ mimeType: mimeType, base64Contents: base64Contents }))
		};
		
		reader.onerror = function() {
			resolve(left(reader.error))
		};

		reader.readAsArrayBuffer(singleFile)
	}
	catch (e) {
		console.log(e.message);
		resolve(left(e.message));
	}
	return (cancelError, onCancelerError, onCancelerSuccess) => onCancelerSuccess();
}