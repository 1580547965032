// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var walletConnected = function (wallet) {
    return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.NearError.create)($foreign["_walletConnected"](wallet));
};
var signOutWallet = function (wallet) {
    return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.NearError.create)($foreign["_signOutWallet"](wallet));
};
var signInWallet = function (wallet) {
    return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.NearError.create)($foreign["_signInWallet"](wallet));
};
var loadContract = function (wallet) {
    return function (accId) {
        return function (viewMethods) {
            return function (changeMethods) {
                return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.NearError.create)($foreign["_loadContract"](wallet)(accId)(viewMethods)(changeMethods));
            };
        };
    };
};
var connectWallet = function (network) {
    return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.NearError.create)($foreign["_connectWallet"](network));
};
var accountId = function (wallet) {
    return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.NearError.create)($foreign["_accountId"](wallet));
};
export {
    _connectWallet,
    _walletConnected,
    _signInWallet,
    _signOutWallet,
    _accountId,
    _loadContract
} from "./foreign.js";
export {
    connectWallet,
    walletConnected,
    signInWallet,
    signOutWallet,
    accountId,
    loadContract
};
