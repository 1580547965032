// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Aff_Compat from "../Effect.Aff.Compat/index.js";
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showString));
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var div = /* #__PURE__ */ Concur_React_DOM.div(/* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray))(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.widgetApplicative);
var NearError = /* #__PURE__ */ (function () {
    function NearError(value0) {
        this.value0 = value0;
    };
    NearError.create = function (value0) {
        return new NearError(value0);
    };
    return NearError;
})();
var ContractError = /* #__PURE__ */ (function () {
    function ContractError(value0) {
        this.value0 = value0;
    };
    ContractError.create = function (value0) {
        return new ContractError(value0);
    };
    return ContractError;
})();
var FileUploadError = /* #__PURE__ */ (function () {
    function FileUploadError(value0) {
        this.value0 = value0;
    };
    FileUploadError.create = function (value0) {
        return new FileUploadError(value0);
    };
    return FileUploadError;
})();
var LocalStorageError = /* #__PURE__ */ (function () {
    function LocalStorageError(value0) {
        this.value0 = value0;
    };
    LocalStorageError.create = function (value0) {
        return new LocalStorageError(value0);
    };
    return LocalStorageError;
})();
var ZkSnarksError = /* #__PURE__ */ (function () {
    function ZkSnarksError(value0) {
        this.value0 = value0;
    };
    ZkSnarksError.create = function (value0) {
        return new ZkSnarksError(value0);
    };
    return ZkSnarksError;
})();
var OtherError = /* #__PURE__ */ (function () {
    function OtherError(value0) {
        this.value0 = value0;
    };
    OtherError.create = function (value0) {
        return new OtherError(value0);
    };
    return OtherError;
})();
var runWeb3E = function (nearweb3) {
    return liftAff(Control_Monad_Except_Trans.runExceptT(nearweb3));
};
var runForeignFunction = function (errf) {
    return function (f) {
        return Effect_Aff_Compat.fromEffectFnAff(f(function ($75) {
            return Data_Either.Left.create(errf($75));
        })(Data_Either.Right.create));
    };
};
var genericWeb3Error = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new NearError(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new ContractError(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new FileUploadError(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new LocalStorageError(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new ZkSnarksError(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return new OtherError(x.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Utility (line 26, column 1 - line 26, column 56): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof NearError) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof ContractError) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof FileUploadError) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof LocalStorageError) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof ZkSnarksError) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof OtherError) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))));
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Utility (line 26, column 1 - line 26, column 56): " + [ x.constructor.name ]);
    }
};
var showWeb3Error = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericWeb3Error)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "NearError";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "ContractError";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "FileUploadError";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "LocalStorageError";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "ZkSnarksError";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "OtherError";
        }
    })))))))
};
var show = /* #__PURE__ */ Data_Show.show(showWeb3Error);
var runWeb3 = function (nearweb3) {
    return bind(liftAff(Control_Monad_Except_Trans.runExceptT(nearweb3)))(function (ea) {
        if (ea instanceof Data_Either.Left) {
            return div([  ])([ text("Error occured while running web3: " + show(ea.value0)) ]);
        };
        if (ea instanceof Data_Either.Right) {
            return pure(ea.value0);
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Utility (line 38, column 5 - line 40, column 26): " + [ ea.constructor.name ]);
    });
};
export {
    NearError,
    ContractError,
    FileUploadError,
    LocalStorageError,
    ZkSnarksError,
    OtherError,
    runForeignFunction,
    runWeb3,
    runWeb3E,
    genericWeb3Error,
    showWeb3Error
};
