// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Concur_Core_FRP from "../Concur.Core.FRP/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Cofree from "../Control.Cofree/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as React from "../React/index.js";
import * as React_DOM_Props from "../React.DOM.Props/index.js";
import * as Unlockable_Wallet_Draggable from "../Unlockable.Wallet.Draggable/index.js";
var loopW = /* #__PURE__ */ Concur_Core_FRP.loopW(Concur_Core_Types.widgetMonad);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var discard1 = /* #__PURE__ */ discard(Concur_Core_Types.widgetBind);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Concur_Core_Types.widgetMonadAff(Data_Monoid.monoidArray));
var pure = /* #__PURE__ */ Control_Applicative.pure(Concur_Core_Types.widgetApplicative);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidLeft = /* #__PURE__ */ Data_Functor.voidLeft(Concur_Core_Props.functorProps);
var widgetAlternative = /* #__PURE__ */ Concur_Core_Types.widgetAlternative(Data_Monoid.monoidArray);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var elemIndex = /* #__PURE__ */ Data_Array.elemIndex(Data_Eq.eqInt);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var dyn = /* #__PURE__ */ Concur_Core_FRP.dyn(Concur_Core_Types.widgetMonad);
var bindCofree = /* #__PURE__ */ Control_Cofree.bindCofree(widgetAlternative);
var discard2 = /* #__PURE__ */ discard(bindCofree);
var bind2 = /* #__PURE__ */ Control_Bind.bind(bindCofree);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var show1 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showArray(Data_Show.showInt));
var sortableContext = /* #__PURE__ */ Unlockable_Wallet_Draggable.sortableContext(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var sortableItem = /* #__PURE__ */ Unlockable_Wallet_Draggable.sortableItem(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var show2 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "source";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt)));
var timer = function (initial) {
    return loopW(initial)(function (n) {
        return discard1(liftAff(Effect_Aff.delay(1000.0)))(function () {
            return pure(n + 1 | 0);
        });
    });
};
var signalComponent = function (start) {
    return Concur_Core_FRP.step(start)(bind(div([  ])([ text("Component = " + show(start)), button([ voidLeft(Concur_React_Props.onClick)(function (v) {
        return v + 1 | 0;
    }) ])([ text("+") ]), button([ voidLeft(Concur_React_Props.onClick)(function (v) {
        return v - 1 | 0;
    }) ])([ text("-") ]) ]))(function (f) {
        return pure(signalComponent(f(start)));
    }));
};
var s = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(/* #__PURE__ */ Control_Cofree.applicativeCofree(widgetAlternative))(signalComponent)([ 1, 2, 3 ]);
var reorder = function (order) {
    return function (dragged) {
        return function (over) {
            var reorder$prime = function (order1) {
                return function (dragged1) {
                    return function (over1) {
                        return bind1(elemIndex(dragged1)(order1))(function (draggedIdx) {
                            return bind1(elemIndex(over1)(order1))(function (overIdx) {
                                return bind1(Data_Array.deleteAt(draggedIdx)(order1))(function (order2) {
                                    return bind1(Data_Array.insertAt(overIdx)(dragged1)(order2))(function (order3) {
                                        return pure1(order3);
                                    });
                                });
                            });
                        });
                    };
                };
            };
            return Data_Maybe.fromMaybe(order)(reorder$prime(order)(dragged)(over));
        };
    };
};
var playComponent = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return Concur_React_DOM["el$prime"](dictShiftMap)(dictMultiAlternative)((function () {
            var $58 = React.unsafeCreateElement($foreign["_playComponent"]);
            return function ($59) {
                return $58(React_DOM_Props.unsafeFromPropsArray($59));
            };
        })());
    };
};
var mainWidget = /* #__PURE__ */ dyn(/* #__PURE__ */ Concur_Core_FRP.loopS(Concur_Core_Types.widgetMonad)(0)(function (n) {
    return discard2(Concur_Core_FRP.display(text("Signal value is " + show(n))))(function () {
        return bind2(timer(n))(function (n$prime) {
            return signalComponent(n$prime);
        });
    });
}));
var itemlist = [ 1, 2, 3, 4, 5 ];
var testSortable = /* #__PURE__ */ (function () {
    var go = function (order) {
        return bind(liftEffect(Effect_Console.log("Order -> " + show1(order))))(function () {
            return bind(sortableContext([ Unlockable_Wallet_Draggable.items(order), Unlockable_Wallet_Draggable.onDropHandler ])(mapFlipped(order)(function (item) {
                return sortableItem([ Unlockable_Wallet_Draggable.itemId(item) ])([ text("hello-" + show(item)) ]);
            })))(function (event) {
                return bind(liftEffect(Effect_Console.log("Event -> " + show2(event))))(function () {
                    return go(reorder(order)(event.source)(event.target));
                });
            });
        });
    };
    return go(itemlist);
})();
var play = /* #__PURE__ */ div([  ])([ testSortable, /* #__PURE__ */ dyn(s), mainWidget, /* #__PURE__ */ playComponent(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap)([  ])([ /* #__PURE__ */ text("Children inside playComponent") ]) ]);
export {
    _playComponent
} from "./foreign.js";
export {
    playComponent,
    play,
    mainWidget,
    timer,
    s,
    signalComponent,
    reorder,
    itemlist,
    testSortable
};
