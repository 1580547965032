// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Concur_React_Run from "../Concur.React.Run/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Foreign from "../Foreign/index.js";
import * as Routing_PushState from "../Routing.PushState/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_Collect from "../Unlockable.Wallet.Collect/index.js";
import * as Unlockable_Wallet_Designer from "../Unlockable.Wallet.Designer/index.js";
import * as Unlockable_Wallet_Holdings from "../Unlockable.Wallet.Holdings/index.js";
import * as Unlockable_Wallet_Navbar from "../Unlockable.Wallet.Navbar/index.js";
import * as Unlockable_Wallet_Play from "../Unlockable.Wallet.Play/index.js";
import * as Unlockable_Wallet_Routing from "../Unlockable.Wallet.Routing/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var notfound = /* #__PURE__ */ div([ /* #__PURE__ */ Unlockable_Wallet_Bootstrap.claz("centered") ])([ /* #__PURE__ */ Concur_React_DOM.h1(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap)([  ])([ /* #__PURE__ */ text("404?!") ]), /* #__PURE__ */ Concur_React_DOM.img(Concur_Core_LiftWidget.widgetLiftWidget)([ /* #__PURE__ */ Concur_React_Props.src("/images/not_the_droids.gif") ]), /* #__PURE__ */ Concur_React_DOM.h3(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap)([  ])([ /* #__PURE__ */ text("These are not the unlockables you are looking for...") ]) ]);
var navbar = function (navInterface) {
    return function (currentRoute) {
        return bind(Unlockable_Wallet_Navbar.navBar(Unlockable_Wallet_Routing.Holdings.value)([ {
            name: "Yours",
            route: Unlockable_Wallet_Routing.Holdings.value
        }, {
            name: "Designer",
            route: Unlockable_Wallet_Routing.Designer.value
        }, {
            name: "API",
            route: Unlockable_Wallet_Routing.API.value
        }, {
            name: "Play",
            route: Unlockable_Wallet_Routing.Play.value
        } ])(currentRoute))(function (newRoute) {
            return bind(liftEffect(navInterface.pushState(Foreign.unsafeToForeign({}))(Unlockable_Wallet_Routing.printRoute(newRoute))))(function () {
                return navbar(navInterface)(newRoute);
            });
        });
    };
};
var api = /* #__PURE__ */ div([ /* #__PURE__ */ Unlockable_Wallet_Bootstrap.claz("container") ])([ /* #__PURE__ */ div([ /* #__PURE__ */ Unlockable_Wallet_Bootstrap.claz("row") ])([ /* #__PURE__ */ div([ /* #__PURE__ */ Unlockable_Wallet_Bootstrap.claz("col") ])([ /* #__PURE__ */ text("API Documentation goes here!") ]) ]) ]);
var main = /* #__PURE__ */ (function () {
    var resolve = function (navInterface) {
        return function (route) {
            var widget = (function () {
                if (route instanceof Unlockable_Wallet_Routing.Holdings) {
                    return Unlockable_Wallet_Holdings.holdings;
                };
                if (route instanceof Unlockable_Wallet_Routing.Designer) {
                    return Unlockable_Wallet_Designer.designer;
                };
                if (route instanceof Unlockable_Wallet_Routing.Collect) {
                    return Unlockable_Wallet_Collect.collect(route.value0)(route.value1);
                };
                if (route instanceof Unlockable_Wallet_Routing.API) {
                    return api;
                };
                if (route instanceof Unlockable_Wallet_Routing.Play) {
                    return Unlockable_Wallet_Play.play;
                };
                return notfound;
            })();
            return Concur_React_Run.runWidgetInDom("root")(alt(navbar(navInterface)(route))(widget));
        };
    };
    return function __do() {
        var navInterface = Routing_PushState.makeInterface();
        navInterface.listen(function (location) {
            return resolve(navInterface)(Unlockable_Wallet_Routing.parseRoute(location.pathname));
        })();
        var route = Unlockable_Wallet_Routing.currentRoute();
        return resolve(navInterface)(route)();
    };
})();
export {
    notfound,
    api,
    navbar,
    main
};
