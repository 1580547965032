// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var write_local_storage = function (key) {
    return function (value) {
        return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.LocalStorageError.create)($foreign["_write_local_storage"](key)(value));
    };
};
var read_local_storage = function (key) {
    return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.LocalStorageError.create)($foreign["_read_local_storage"](key)(Data_Maybe.Just.create)(Data_Maybe.Nothing.value));
};
var keyNetwork = "network";
var setNetwork = /* #__PURE__ */ write_local_storage(keyNetwork);
var getNetwork = /* #__PURE__ */ bind(/* #__PURE__ */ read_local_storage(keyNetwork))(function (maybeNetwork) {
    if (maybeNetwork instanceof Data_Maybe.Just) {
        return pure(maybeNetwork.value0);
    };
    if (maybeNetwork instanceof Data_Maybe.Nothing) {
        return bind(write_local_storage(keyNetwork)("mainnet"))(function () {
            return pure("mainnet");
        });
    };
    throw new Error("Failed pattern match at Unlockable.Wallet.Config (line 17, column 5 - line 21, column 25): " + [ maybeNetwork.constructor.name ]);
});
export {
    _read_local_storage,
    _write_local_storage
} from "./foreign.js";
export {
    keyNetwork,
    getNetwork,
    setNetwork,
    read_local_storage,
    write_local_storage
};
