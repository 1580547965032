"use strict";

// This is utter rot - and i have no idea how to do this in a foreign module
//  - probably the solution is to use a proper build chain / bundler before
//    spago builds (TODO?)
//
const { wrap_aff, wrap_maybe } = require('../../src/ForeignHelpers.js')

export const _get_holdings = contract => account => wrap_aff((left, right) =>
	contract
		.get_holdings({ account })
		.then((value) =>
			//
			// contract returns [["designer_account_id", "unlockable_name"], ...]
			//       convert to { designer_accountid, unlockable_name }
			//
			right(
				value.map(([designer_account_id, unlockable_name]) => {
					return { designer_account_id, unlockable_name }
				})
			)
		)
)

export const _get_designer = contract => designer_account_id => just => nothing => wrap_aff((left, right) =>
	contract
		.get_designer({ designer_account_id })
		//
		// contract returns [name, Option<icon>]
		//       convert to { name, icon }
		//
		.then((value) => {
			if (value == null) {
				// When designer is not found
				return right(nothing)
			}
			else {
				// When designer is found
				const maybe = wrap_maybe(just, nothing)
				return right(just({ name: value[0], icon: maybe(value[1]) }))
			}
		})
)

export const _get_unlockable = contract => designer_account_id => unlockable_name => just => nothing => wrap_aff((left, right) =>
	contract
		.get_unlockable({ designer_account_id, unlockable_name })
		//
		// contract returns [Option<icon>, supply, available]
		//       convert to { icon, supply, available }
		//
		.then((value) => {
			if (value == null) {
				// When collectable is not found
				return right(nothing)
			}
			else {
				// When collectable is found
				const maybe = wrap_maybe(just, nothing)
				return right(just({ icon: maybe(value[0]), supply: value[1], available: value[2] }))
			}
		})
)

export const _get_holders = contract => designer_account_id => unlockable_name => wrap_aff((left, right) =>
	contract
		.get_holders({ designer_account_id, unlockable_name })
		//
		// contract returns [string]
		//
		.then(right)
)

export const _get_holding = contract => designer_account_id => unlockable_name => holder_account_id => just => nothing => wrap_aff((left, right) =>
	contract
		.get_holding({ designer_account_id, unlockable_name, holder_account_id })
		//
		// contract returns some sort of tuple
		//
		.then((value) => {
			if (value != null) {
				return right(just({ id: value[0], rarity_mask: value[1] }))
			}
			else {
				return right(nothing)
			}
		})
)

export const _get_designs = contract => designer_account_id => wrap_aff((left, right) =>
	contract
		.get_designs({ designer_account_id })
		//
		// contract returns [name, ..]
		//
		.then(right)
)

export const _is_holder = contract => designer_account_id => unlockable_name => holder_account_id => wrap_aff((left, right) =>
	contract
		.is_holder({ designer_account_id, unlockable_name, holder_account_id })
		//
		// contract returns bool
		//
		.then(right)
)

export const _enroll = contract => name => icon => wrap_aff((left, right) =>
	contract
		.enroll({ name, icon })
		//
		// contract returns unit
		//
		.then(right)
)

export const _design = contract => name => supply => wrap_aff((left, right) =>
	contract
		.design({ name, supply })
		//
		// contract returns unit
		//
		.then(right)
)

export const _collect = contract => designer_account_id => unlockable_name => wrap_aff((left, right) =>
	contract
		.collect({ designer_account_id, unlockable_name })
			//
			// contract returns unit
			//
		.then(right)
)

export const _set_designer_icon = contract => icon => wrap_aff((left, right) =>
	contract
		.set_designer_icon({ icon })
		//
		// contract returns unit
		//
		.then(right)
)

export const _set_unlockable_icon_wallet = contract => unlockable_name => icon => wrap_aff((left, right) =>
	contract
		.set_unlockable_icon({ unlockable_name, icon })
		//
		// contract returns unit
		//
		.then(right)
)

// Prototype for calling the NEAR RPC directly (not via WalletConnection)
// - tested to work with bigger files (which would crash the Wallet URL)
// - probably points to a refactor needed everywhere to use this approach
// - will probably break most of the app as it relied on the nice redirect / refresh opportunity :)
//
export const _set_unlockable_icon = connectedContract => unlockable_name => icon => wrap_aff((left, right) => {

	console.log(`Calling set_unlockable_icon(${unlockable_name}, ${icon})`)

	// Try from first principles

	const nearAPI = require("near-api-js");
	const { connect, keyStores, WalletConnection, Account, Contract } = nearAPI;

	return connect({
		networkId: 'testnet',
		nodeUrl: 'https://rpc.testnet.near.org',
		keyStore: new keyStores.BrowserLocalStorageKeyStore(),
	})
	.then((connection) => {
		console.log("Connection:")
		console.log(connection)

		//
		// For now pass the connected contract
		//
		const account = new Account(connection.connection, connectedContract.account.accountId)

		console.log("Account:")
		console.log(account)

		const contract = new Contract(
			account,
			connectedContract.contractId,
			{ viewMethods: [], changeMethods: ["set_unlockable_icon"] }
		)
		console.log("Contract:")
		console.log(contract)

		return contract
			.set_unlockable_icon({ unlockable_name, icon })
			.then((value) => {
				//
				// contract returns unit
				//
				return right(value)
			})

	})
})

export const _set_unlockable_description = connectedContract => unlockable_name => description => wrap_aff((left, right) => {
	const { connect, keyStores, Account, Contract } = require("near-api-js");

	return connect({
		networkId : 'testnet',
		nodeUrl   : 'https://rpc.testnet.near.org',
		keyStore  : new keyStores.BrowserLocalStorageKeyStore(),
	})
	.then((connection) => {
		const account  = new Account(connection.connection, connectedContract.account.accountId)
		const contract = new Contract(account, connectedContract.contractId, {
			viewMethods   : [],
			changeMethods : [ "set_unlockable_description" ],
		})

		console.log("Contract:", contract)

		return contract
			.set_unlockable_description({ unlockable_name, description })
			.then(right)
	})
})
