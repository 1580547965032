// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var toBlob = function (uf) {
    return "data:" + (uf.mimeType + (";base64," + uf.base64Contents));
};
var get_file_contents = function (obj) {
    return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.FileUploadError.create)($foreign["_get_file_contents"](obj));
};
var fileUploadWidget = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind)(/* #__PURE__ */ Concur_React_DOM.input(Concur_Core_LiftWidget.widgetLiftWidget)([ /* #__PURE__ */ Concur_React_Props["_type"]("file"), Concur_React_Props.onChange ]))(function (v) {
    return Unlockable_Wallet_Utility.runWeb3(get_file_contents(v));
});
export {
    _get_file_contents
} from "./foreign.js";
export {
    get_file_contents,
    toBlob,
    fileUploadWidget
};
