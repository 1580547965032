// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var Common = /* #__PURE__ */ (function () {
    function Common() {

    };
    Common.value = new Common();
    return Common;
})();
var Uncommon = /* #__PURE__ */ (function () {
    function Uncommon() {

    };
    Uncommon.value = new Uncommon();
    return Uncommon;
})();
var Rare = /* #__PURE__ */ (function () {
    function Rare() {

    };
    Rare.value = new Rare();
    return Rare;
})();
var Legendary = /* #__PURE__ */ (function () {
    function Legendary() {

    };
    Legendary.value = new Legendary();
    return Legendary;
})();
var Mythic = /* #__PURE__ */ (function () {
    function Mythic() {

    };
    Mythic.value = new Mythic();
    return Mythic;
})();
var unlockable_contract = function (wallet) {
    return Unlockable_Wallet_Near.loadContract(wallet)("2.burner.unlockable.testnet")([ "get_holdings", "get_designer", "get_unlockable", "get_holders", "get_holding", "get_designs", "is_holder" ])([ "enroll", "design", "collect", "set_designer_icon", "set_unlockable_icon", "set_unlockable_description" ]);
};
var set_unlockable_icon = function (contract) {
    return function (unlockable_name) {
        return function (icon) {
            return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_set_unlockable_icon"](contract)(unlockable_name)(icon));
        };
    };
};
var set_designer_icon = function (contract) {
    return function (icon) {
        return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_set_designer_icon"](contract)(icon));
    };
};
var is_holder = function (contract) {
    return function (designer_account_id) {
        return function (unlockable_name) {
            return function (holder_account_id) {
                return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_is_holder"](contract)(designer_account_id)(unlockable_name)(holder_account_id));
            };
        };
    };
};
var get_unlockable = function (contract) {
    return function (designer_account_id) {
        return function (unlockable_name) {
            return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_get_unlockable"](contract)(designer_account_id)(unlockable_name)(Data_Maybe.Just.create)(Data_Maybe.Nothing.value));
        };
    };
};
var get_holdings = function (contract) {
    return function (account) {
        return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_get_holdings"](contract)(account));
    };
};
var get_holding = function (contract) {
    return function (designer_account_id) {
        return function (unlockable_name) {
            return function (holder_account_id) {
                return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_get_holding"](contract)(designer_account_id)(unlockable_name)(holder_account_id)(Data_Maybe.Just.create)(Data_Maybe.Nothing.value));
            };
        };
    };
};
var get_holders = function (contract) {
    return function (designer_account_id) {
        return function (unlockable_name) {
            return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_get_holders"](contract)(designer_account_id)(unlockable_name));
        };
    };
};
var get_designs = function (contract) {
    return function (designer_account_id) {
        return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_get_designs"](contract)(designer_account_id));
    };
};
var get_designer = function (contract) {
    return function (account) {
        return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_get_designer"](contract)(account)(Data_Maybe.Just.create)(Data_Maybe.Nothing.value));
    };
};
var getRarity = function (rarity_mask) {
    if ((rarity_mask & 255) === 255) {
        return Mythic.value;
    };
    if ((rarity_mask & 63) === 63) {
        return Legendary.value;
    };
    if ((rarity_mask & 15) === 15) {
        return Rare.value;
    };
    if ((rarity_mask & 3) === 3) {
        return Uncommon.value;
    };
    if (Data_Boolean.otherwise) {
        return Common.value;
    };
    throw new Error("Failed pattern match at Unlockable.Wallet.Unlockable (line 55, column 1 - line 55, column 27): " + [ rarity_mask.constructor.name ]);
};
var genericRarity = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Common.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Uncommon.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return Rare.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return Legendary.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return Mythic.value;
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Unlockable (line 19, column 1 - line 19, column 50): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Common) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Uncommon) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Rare) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof Legendary) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof Mythic) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
        };
        throw new Error("Failed pattern match at Unlockable.Wallet.Unlockable (line 19, column 1 - line 19, column 50): " + [ x.constructor.name ]);
    }
};
var showRarity = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericRarity)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Common";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Uncommon";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Rare";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Legendary";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Mythic";
        }
    }))))))
};
var enroll = function (contract) {
    return function (name) {
        return function (icon) {
            return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_enroll"](contract)(name)(icon));
        };
    };
};
var design = function (contract) {
    return function (name) {
        return function (supply) {
            return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_design"](contract)(name)(supply));
        };
    };
};
var collect = function (contract) {
    return function (designer_account_id) {
        return function (unlockable_name) {
            return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.ContractError.create)($foreign["_collect"](contract)(designer_account_id)(unlockable_name));
        };
    };
};
export {
    _get_holdings,
    _get_designer,
    _get_unlockable,
    _get_holders,
    _get_holding,
    _get_designs,
    _is_holder,
    _enroll,
    _design,
    _collect,
    _set_designer_icon,
    _set_unlockable_icon,
    _set_unlockable_icon_wallet
} from "./foreign.js";
export {
    Common,
    Uncommon,
    Rare,
    Legendary,
    Mythic,
    unlockable_contract,
    getRarity,
    get_holdings,
    get_designer,
    get_unlockable,
    get_holders,
    get_holding,
    get_designs,
    is_holder,
    enroll,
    design,
    collect,
    set_designer_icon,
    set_unlockable_icon,
    genericRarity,
    showRarity
};
