// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_ConnectWallet from "../Unlockable.Wallet.ConnectWallet/index.js";
import * as Unlockable_Wallet_Routing from "../Unlockable.Wallet.Routing/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var nav = /* #__PURE__ */ Concur_React_DOM.nav(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Concur_Core_Props.functorProps);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var ul = /* #__PURE__ */ Concur_React_DOM.ul(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var eq = /* #__PURE__ */ Data_Eq.eq(Unlockable_Wallet_Routing.eqRoute);
var li = /* #__PURE__ */ Concur_React_DOM.li(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var navBar = function (headerRoute) {
    return function (links) {
        return function (currentRoute) {
            return nav([ Unlockable_Wallet_Bootstrap.clazz([ "navbar", "navbar-light", "navbar-expand-lg", "bg-light" ]) ])([ div([ Unlockable_Wallet_Bootstrap.claz("container") ])([ a([ Unlockable_Wallet_Bootstrap.clazz([ "navbar-brand", "mb-0", "h1" ]), voidRight(headerRoute)(Concur_React_Props.onClick) ])([ text("Unlockables") ]), button([ Unlockable_Wallet_Bootstrap.claz("navbar-toggler"), Unlockable_Wallet_Bootstrap.dataBsToggle("collapse"), Unlockable_Wallet_Bootstrap.dataBsTarget("#the_navbar") ])([ span([ Unlockable_Wallet_Bootstrap.claz("navbar-toggler-icon") ])([  ]) ]), div([ Concur_React_Props["_id"]("the_navbar"), Unlockable_Wallet_Bootstrap.clazz([ "collapse", "navbar-collapse" ]) ])([ ul([ Unlockable_Wallet_Bootstrap.clazz([ "nav", "navbar-nav" ]) ])(mapFlipped(links)(function (v) {
                var $21 = eq(v.route)(currentRoute);
                if ($21) {
                    return li([ Unlockable_Wallet_Bootstrap.claz("nav-item") ])([ a([ voidRight(v.route)(Concur_React_Props.onClick), Unlockable_Wallet_Bootstrap.clazz([ "nav-link", "active" ]) ])([ text(v.name) ]) ]);
                };
                return li([ Unlockable_Wallet_Bootstrap.claz("nav-item") ])([ a([ voidRight(v.route)(Concur_React_Props.onClick), Unlockable_Wallet_Bootstrap.claz("nav-link") ])([ text(v.name) ]) ]);
            })), ul([ Unlockable_Wallet_Bootstrap.clazz([ "nav", "navbar-nav", "navbar-right", "ms-auto" ]) ])([ li([ Unlockable_Wallet_Bootstrap.claz("nav-item") ])([ Unlockable_Wallet_ConnectWallet.connectWidget ]) ]) ]) ]) ]);
        };
    };
};
export {
    navBar
};
