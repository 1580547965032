// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as React_DOM_Props from "../React.DOM.Props/index.js";
import * as Unlockable_Wallet_Config from "../Unlockable.Wallet.Config/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h5 = /* #__PURE__ */ Concur_React_DOM.h5(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var ul = /* #__PURE__ */ Concur_React_DOM.ul(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var li = /* #__PURE__ */ Concur_React_DOM.li(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var dataBsToggle = /* #__PURE__ */ (function () {
    var $30 = React_DOM_Props.unsafeMkProps("data-bs-toggle");
    return function ($31) {
        return Concur_Core_Props.PrimProp.create($30($31));
    };
})();
var dataBsTarget = /* #__PURE__ */ (function () {
    var $32 = React_DOM_Props.unsafeMkProps("data-bs-target");
    return function ($33) {
        return Concur_Core_Props.PrimProp.create($32($33));
    };
})();
var clazzz = function (xs) {
    return function (ts) {
        return Concur_React_Props.classList(map(Data_Maybe.Just.create)(append(xs)(map(Data_Tuple.fst)(Data_Array.filter(Data_Tuple.snd)(ts)))));
    };
};
var clazz = function (xs) {
    return Concur_React_Props.classList(map(Data_Maybe.Just.create)(xs));
};
var connectWalletThen = function (widget) {
    return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
        return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
            return Unlockable_Wallet_Near.walletConnected(wallet);
        });
    })))(function (connected) {
        var $24 = !connected;
        if ($24) {
            return div([ clazz([ "container", "text-center" ]) ])([ h5([  ])([ text("Please connect your wallet to use unlockables!") ]) ]);
        };
        return widget;
    });
};
var claz = function (x) {
    return Concur_React_Props.className(x);
};
var $lazy_walletButton = /* #__PURE__ */ $runtime_lazy("walletButton", "Unlockable.Wallet.Bootstrap", function () {
    return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
        return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
            return bind1(Unlockable_Wallet_Near.walletConnected(wallet))(function (connected) {
                return bind1(Unlockable_Wallet_Near.accountId(wallet))(function (account) {
                    return pure({
                        wallet: wallet,
                        connected: connected,
                        account: account
                    });
                });
            });
        });
    })))(function (v) {
        var $26 = !v.connected;
        if ($26) {
            return bind(button([ clazz([ "btn", "btn-primary" ]), Concur_React_Props.onClick ])([ text("Connect!") ]))(function () {
                return bind(Unlockable_Wallet_Utility.runWeb3(Unlockable_Wallet_Near.signInWallet(v.wallet)))(function () {
                    return $lazy_walletButton(68);
                });
            });
        };
        return bind(div([ claz("btn-group") ])([ button([ clazz([ "btn", "btn-danger", "dropdown-toggle" ]), dataBsToggle("dropdown") ])([ text(v.account) ]), ul([ claz("dropdown-menu") ])([ li([  ])([ a([ claz("dropdown-item"), Concur_React_Props.onClick ])([ text("Disconnect!") ]) ]) ]) ]))(function () {
            return bind(Unlockable_Wallet_Utility.runWeb3(Unlockable_Wallet_Near.signOutWallet(v.wallet)))(function () {
                return $lazy_walletButton(81);
            });
        });
    });
});
var walletButton = /* #__PURE__ */ $lazy_walletButton(54);
var _for = /* #__PURE__ */ (function () {
    var $34 = React_DOM_Props.unsafeMkProps("htmlFor");
    return function ($35) {
        return Concur_Core_Props.PrimProp.create($34($35));
    };
})();
export {
    dataBsToggle,
    dataBsTarget,
    _for,
    clazzz,
    clazz,
    claz,
    connectWalletThen,
    walletButton
};
