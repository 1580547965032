// Generated by purs version 0.15.4
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Props from "../Concur.Core.Props/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_Config from "../Unlockable.Wallet.Config/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Concur_Core_Types.widgetBind);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var button = /* #__PURE__ */ Concur_React_DOM.button(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var ul = /* #__PURE__ */ Concur_React_DOM.ul(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var li = /* #__PURE__ */ Concur_React_DOM.li(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var voidLeft = /* #__PURE__ */ Data_Functor.voidLeft(Concur_Core_Props.functorProps);
var $lazy_connectWidget = /* #__PURE__ */ $runtime_lazy("connectWidget", "Unlockable.Wallet.ConnectWallet", function () {
    return bind(Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
        return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
            return bind1(Unlockable_Wallet_Near.walletConnected(wallet))(function (connected) {
                return pure({
                    wallet: wallet,
                    connected: connected
                });
            });
        });
    })))(function (v) {
        return discard((function () {
            if (v.connected) {
                return bind(Unlockable_Wallet_Utility.runWeb3(Unlockable_Wallet_Near.accountId(v.wallet)))(function (account) {
                    return bind(div([ Unlockable_Wallet_Bootstrap.claz("btn-group") ])([ button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-danger", "dropdown-toggle" ]), Unlockable_Wallet_Bootstrap.dataBsToggle("dropdown") ])([ text(account) ]), ul([ Unlockable_Wallet_Bootstrap.claz("dropdown-menu") ])([ li([  ])([ a([ Unlockable_Wallet_Bootstrap.claz("dropdown-item"), Concur_React_Props.onClick ])([ text("Signout!") ]) ]) ]) ]))(function () {
                        return Unlockable_Wallet_Utility.runWeb3(Unlockable_Wallet_Near.signOutWallet(v.wallet));
                    });
                });
            };
            return bind(div([ Unlockable_Wallet_Bootstrap.claz("btn-group") ])([ button([ Unlockable_Wallet_Bootstrap.clazz([ "btn", "btn-danger", "dropdown-toggle" ]), Unlockable_Wallet_Bootstrap.dataBsToggle("dropdown") ])([ text("Connect Wallet!") ]), ul([ Unlockable_Wallet_Bootstrap.claz("dropdown-menu") ])([ li([  ])([ a([ Unlockable_Wallet_Bootstrap.claz("dropdown-item"), voidLeft(Concur_React_Props.onClick)("mainnet") ])([ text("Connect to mainnet") ]), a([ Unlockable_Wallet_Bootstrap.claz("dropdown-item"), voidLeft(Concur_React_Props.onClick)("testnet") ])([ text("Connect to testnet") ]) ]) ]) ]))(function (v1) {
                return Unlockable_Wallet_Utility.runWeb3(bind1(Unlockable_Wallet_Config.setNetwork(v1))(function () {
                    return bind1(Unlockable_Wallet_Near.connectWallet(v1))(function (new_wallet) {
                        return Unlockable_Wallet_Near.signInWallet(new_wallet);
                    });
                }));
            });
        })())(function () {
            return $lazy_connectWidget(56);
        });
    });
});
var connectWidget = /* #__PURE__ */ $lazy_connectWidget(14);
export {
    connectWidget
};
