// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Cofree from "../Control.Cofree/index.js";
import * as Control_Comonad from "../Control.Comonad/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
var update = Control_Cofree.tail;
var step = Control_Cofree.mkCofree;
var poll = function (dictMonad) {
    var Apply0 = (dictMonad.Bind1()).Apply0();
    var apply = Control_Apply.apply(Apply0);
    var Functor0 = Apply0.Functor0();
    var map = Data_Functor.map(Functor0);
    var extract = Control_Comonad.extract(Control_Cofree.comonadCofree(Functor0));
    return function (b) {
        return apply(map(step)(extract(b)))(map(poll(dictMonad))(update(b)));
    };
};
var oneShot = function (dictMonad) {
    var Bind1 = dictMonad.Bind1();
    var extract = Control_Comonad.extract(Control_Cofree.comonadCofree((Bind1.Apply0()).Functor0()));
    var bind = Control_Bind.bind(Bind1);
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (s) {
        var v = extract(s);
        if (v instanceof Data_Maybe.Nothing) {
            return bind(update(s))(oneShot(dictMonad));
        };
        if (v instanceof Data_Maybe.Just) {
            return pure(v.value0);
        };
        throw new Error("Failed pattern match at Concur.Core.FRP (line 164, column 13 - line 166, column 19): " + [ v.constructor.name ]);
    };
};
var loopW = function (dictMonad) {
    var map = Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0());
    return function (a) {
        return function (f) {
            var go = function (x) {
                return loopW(dictMonad)(x)(f);
            };
            return step(a)(map(go)(f(a)));
        };
    };
};
var loopS = function (dictMonad) {
    var Bind1 = dictMonad.Bind1();
    var extract = Control_Comonad.extract(Control_Cofree.comonadCofree((Bind1.Apply0()).Functor0()));
    var bind = Control_Bind.bind(Bind1);
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (a) {
        return function (f) {
            var $$this = f(a);
            return step(extract($$this))(bind(update($$this))(function (s) {
                return pure(loopS(dictMonad)(extract(s))(f));
            }));
        };
    };
};
var stateLoopS = function (dictMonad) {
    var loopS1 = loopS(dictMonad);
    return function (dictAlternative) {
        var map = Data_Functor.map(Control_Cofree.functorCofree(((dictAlternative.Plus1()).Alt0()).Functor0()));
        var pure = Control_Applicative.pure(Control_Cofree.applicativeCofree(dictAlternative));
        return function (def) {
            return function (w) {
                return map(Data_Either.hush)(loopS1(new Data_Either.Left(def))(Data_Either.either(w)(function ($114) {
                    return pure(Data_Either.Right.create($114));
                })));
            };
        };
    };
};
var justWait = function (dictMonad) {
    return function (dictAlternative) {
        var bind = Control_Bind.bind(Control_Cofree.bindCofree(dictAlternative));
        var pure = Control_Applicative.pure(Control_Cofree.applicativeCofree(dictAlternative));
        return function (b) {
            return function (s) {
                return function (f) {
                    return bind(s)(function (m) {
                        if (m instanceof Data_Maybe.Nothing) {
                            return pure(b);
                        };
                        if (m instanceof Data_Maybe.Just) {
                            return f(m.value0);
                        };
                        throw new Error("Failed pattern match at Concur.Core.FRP (line 61, column 3 - line 63, column 18): " + [ m.constructor.name ]);
                    });
                };
            };
        };
    };
};
var hold = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (a) {
        return function (w) {
            return step(a)(bind(w)(function (a$prime) {
                return pure(hold(dictMonad)(a$prime)(w));
            }));
        };
    };
};
var foldp = function (dictFunctor) {
    var extract = Control_Comonad.extract(Control_Cofree.comonadCofree(dictFunctor));
    var map = Data_Functor.map(dictFunctor);
    return function (f) {
        return function (a) {
            return function (sb) {
                var a$prime = f(a)(extract(sb));
                return step(a$prime)(map(foldp(dictFunctor)(f)(a$prime))(update(sb)));
            };
        };
    };
};
var fireOnce = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (dictPlus) {
        var empty = Control_Plus.empty(dictPlus);
        return function (w) {
            return step(Data_Maybe.Nothing.value)(bind(w)(function (a) {
                return pure(step(new Data_Maybe.Just(a))(empty));
            }));
        };
    };
};
var justEffect = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(dictMonadEffect);
    return function (dictMonad) {
        var justWait1 = justWait(dictMonad);
        var fireOnce1 = fireOnce(dictMonad);
        return function (dictAlternative) {
            var justWait2 = justWait1(dictAlternative);
            var fireOnce2 = fireOnce1(dictAlternative.Plus1());
            return function (b) {
                return function (e) {
                    return function (f) {
                        return justWait2(b)(fireOnce2(liftEffect(e)))(f);
                    };
                };
            };
        };
    };
};
var dyn = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    return function (s) {
        return bind(update(s))(dyn(dictMonad));
    };
};
var display = function (w) {
    return step(Data_Unit.unit)(w);
};
var fireOnce_ = function (dictMonad) {
    var applySecond = Control_Apply.applySecond((dictMonad.Bind1()).Apply0());
    return function (dictPlus) {
        var empty = Control_Plus.empty(dictPlus);
        return function (w) {
            return display(applySecond(w)(empty));
        };
    };
};
var demand$prime = function (dictMonad) {
    var oneShot1 = oneShot(dictMonad);
    return function (f) {
        return oneShot1(f(Data_Maybe.Nothing.value));
    };
};
var demand = function (dictMonad) {
    return oneShot(dictMonad);
};
var demandLoop = function (dictMonad) {
    var demand1 = demand(dictMonad);
    var stateLoopS1 = stateLoopS(dictMonad);
    return function (dictAlternative) {
        var stateLoopS2 = stateLoopS1(dictAlternative);
        return function (def) {
            return function (w) {
                return demand1(stateLoopS2(def)(w));
            };
        };
    };
};
var debounce = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (dictAlt) {
        var alt = Control_Alt.alt(dictAlt);
        var Functor0 = dictAlt.Functor0();
        var map = Data_Functor.map(Functor0);
        var voidRight = Data_Functor.voidRight(Functor0);
        return function (dictMonadAff) {
            var liftAff = Effect_Aff_Class.liftAff(dictMonadAff);
            return function (timeoutMs) {
                return function (ainit) {
                    return function (winit) {
                        var go$prime = function (a) {
                            return function (w) {
                                return bind(alt(map(Data_Maybe.Just.create)(w(a)))(voidRight(Data_Maybe.Nothing.value)(liftAff(Effect_Aff.delay(timeoutMs)))))(function (res) {
                                    if (res instanceof Data_Maybe.Nothing) {
                                        return pure(go(a)(w));
                                    };
                                    if (res instanceof Data_Maybe.Just) {
                                        return go$prime(res.value0)(w);
                                    };
                                    throw new Error("Failed pattern match at Concur.Core.FRP (line 214, column 7 - line 218, column 28): " + [ res.constructor.name ]);
                                });
                            };
                        };
                        var go = function (a) {
                            return function (w) {
                                return step(a)(bind(w(a))(function (a$prime) {
                                    return go$prime(a$prime)(w);
                                }));
                            };
                        };
                        return go(ainit)(winit);
                    };
                };
            };
        };
    };
};
var always = function (dictMonad) {
    return function (dictAlternative) {
        return Control_Applicative.pure(Control_Cofree.applicativeCofree(dictAlternative));
    };
};
export {
    step,
    display,
    fireOnce,
    fireOnce_,
    justWait,
    justEffect,
    always,
    update,
    poll,
    hold,
    loopW,
    loopS,
    foldp,
    dyn,
    oneShot,
    demand,
    demand$prime,
    demandLoop,
    stateLoopS,
    debounce
};
