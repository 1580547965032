// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Unlockable_Wallet_Bootstrap from "../Unlockable.Wallet.Bootstrap/index.js";
import * as Unlockable_Wallet_Config from "../Unlockable.Wallet.Config/index.js";
import * as Unlockable_Wallet_Near from "../Unlockable.Wallet.Near/index.js";
import * as Unlockable_Wallet_Unlockable from "../Unlockable.Wallet.Unlockable/index.js";
import * as Unlockable_Wallet_Utility from "../Unlockable.Wallet.Utility/index.js";
var show = /* #__PURE__ */ Data_Show.show(Unlockable_Wallet_Unlockable.showRarity);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var h5 = /* #__PURE__ */ Concur_React_DOM.h5(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var p = /* #__PURE__ */ Concur_React_DOM.p(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Concur_Core_Types.widgetAlt(Data_Monoid.monoidArray));
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var init_tilt = function (search) {
    return function (options) {
        return Unlockable_Wallet_Utility.runForeignFunction(Unlockable_Wallet_Utility.OtherError.create)($foreign["_init_tilt"](search)(options));
    };
};
var initTiltWidget = function (query) {
    return function (options) {
        return Unlockable_Wallet_Utility.runWeb3(init_tilt(query)(options));
    };
};
var unlockableCard$prime = function (designerAccountId) {
    return function (unlockableName) {
        return function (details) {
            var cardId = Data_String_Common.toUpper(Data_Int.toStringAs(Data_Int.hexadecimal)(details.holdingDetails.id));
            var rarity = show(Unlockable_Wallet_Unlockable.getRarity(details.holdingDetails.rarity_mask));
            var header = div([ Unlockable_Wallet_Bootstrap.claz("unlockable-card-header") ])([ div([ Unlockable_Wallet_Bootstrap.clazz([ "rarity-badge", "hoverable" ]), Concur_React_Props.title(rarity + (" [#" + (cardId + "]"))) ])([  ]), h5([ Unlockable_Wallet_Bootstrap.claz("unlockable-card-title") ])([ text(unlockableName) ]) ]);
            var image = div([ Unlockable_Wallet_Bootstrap.claz("unlockable-card-image-container") ])([ div([ Unlockable_Wallet_Bootstrap.claz("unlockable-card-image"), Concur_React_Props.style({
                "background-image": "url(" + (Data_Maybe.maybe("/images/missing.gif")(function (d) {
                    return d;
                })(details.unlockableDetails.icon) + ")")
            }) ])([  ]) ]);
            var contents = div([ Unlockable_Wallet_Bootstrap.claz("unlockable-card-contents") ])([ p([ Unlockable_Wallet_Bootstrap.claz("unlockable-card-description") ])([ text("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.") ]), div([ Unlockable_Wallet_Bootstrap.claz("unlockable-card-details") ])([ span([ Unlockable_Wallet_Bootstrap.clazz([ "hoverable" ]), Concur_React_Props.title(designerAccountId) ])([ text(details.designerDetails.name) ]), span([ Unlockable_Wallet_Bootstrap.claz("grow"), Concur_React_Props.style({
                "min-width": "5px"
            }) ])([  ]), span([ Unlockable_Wallet_Bootstrap.clazz([ "hoverable" ]), Concur_React_Props.title("Held by " + Data_String_Common.joinWith(", ")(details.holders)) ])([ text(show1(details.unlockableDetails.available)), span([ Concur_React_Props.style({
                margin: "0 0.25em",
                "font-size": "75%"
            }) ])([ text("of") ]), text(show1(details.unlockableDetails.supply)) ]) ]) ]);
            return bind(alt(div([ Concur_React_Props["_id"](cardId), Unlockable_Wallet_Bootstrap.clazz([ "disable-user-select", "unlockable-card", Data_String_Common.toLower(rarity) ]) ])([ header, image, contents ]))(initTiltWidget(cardId)({
                reverse: true,
                "full-page-listening": true
            })))(function () {
                return unlockableCard$prime(designerAccountId)(unlockableName)(details);
            });
        };
    };
};
var getUnlockableHolding = function (designerAccountId) {
    return function (unlockableName) {
        return bind1(bind1(Unlockable_Wallet_Config.getNetwork)(function (network) {
            return bind1(Unlockable_Wallet_Near.connectWallet(network))(function (wallet) {
                return bind1(Unlockable_Wallet_Near.accountId(wallet))(function (myAccountId) {
                    return bind1(Unlockable_Wallet_Unlockable.unlockable_contract(wallet))(function (contract) {
                        return bind1(Unlockable_Wallet_Unlockable.get_designer(contract)(designerAccountId))(function (designerDetails) {
                            return bind1(Unlockable_Wallet_Unlockable.get_unlockable(contract)(designerAccountId)(unlockableName))(function (unlockableDetails) {
                                return bind1(Unlockable_Wallet_Unlockable.get_holders(contract)(designerAccountId)(unlockableName))(function (holders) {
                                    return bind1(Unlockable_Wallet_Unlockable.get_holding(contract)(designerAccountId)(unlockableName)(myAccountId))(function (holdingDetails) {
                                        return pure({
                                            designerDetails: designerDetails,
                                            unlockableDetails: unlockableDetails,
                                            holdingDetails: holdingDetails,
                                            holders: holders
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        }))(function (details) {
            return pure((function () {
                if (details.designerDetails instanceof Data_Maybe.Just && (details.unlockableDetails instanceof Data_Maybe.Just && details.holdingDetails instanceof Data_Maybe.Just)) {
                    return new Data_Maybe.Just({
                        designerDetails: details.designerDetails.value0,
                        unlockableDetails: details.unlockableDetails.value0,
                        holdingDetails: details.holdingDetails.value0,
                        holders: details.holders
                    });
                };
                return Data_Maybe.Nothing.value;
            })());
        });
    };
};
var unlockableCard = function (designerAccountId) {
    return function (unlockableName) {
        return bind(Unlockable_Wallet_Utility.runWeb3(getUnlockableHolding(designerAccountId)(unlockableName)))(function (unlockableHolding) {
            if (unlockableHolding instanceof Data_Maybe.Just) {
                return unlockableCard$prime(designerAccountId)(unlockableName)(unlockableHolding.value0);
            };
            if (unlockableHolding instanceof Data_Maybe.Nothing) {
                return text("Unable to find the unlockable holding (have you unlocked it?)");
            };
            throw new Error("Failed pattern match at Unlockable.Wallet.UnlockableCard (line 155, column 5 - line 157, column 94): " + [ unlockableHolding.constructor.name ]);
        });
    };
};
export {
    _init_tilt
} from "./foreign.js";
export {
    init_tilt,
    initTiltWidget,
    unlockableCard$prime,
    getUnlockableHolding,
    unlockableCard
};
